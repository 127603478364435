<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 评价管理 </template>
      <template #input>
        <div class="findItem">
          <!-- 支付方式 -->
          <a-select
            placeholder="评价"
            style="width: 120px"
            @change="handleChange"
            v-model="evaluation"
          >
            <a-icon
              slot="suffixIcon"
              type="caret-down"
              style="color: #3681ef"
            />
            <a-select-option value="1"> 差评 </a-select-option>
            <a-select-option value="2"> 中评 </a-select-option>
            <a-select-option value="3"> 好评 </a-select-option>
          </a-select>
          <a-input placeholder="请输入关键字" v-model="keyword" />
          <a-button type="primary" @click="search()">搜索</a-button>
          <a-button
            class="all_btn_border btnBorder"
            @click="reset"
            >重置</a-button
          >
        </div>
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="3" @change="callback">
        <a-tab-pane key="3" tab="全部评价"></a-tab-pane>
        <a-tab-pane key="0">
          <template slot="tab"
            >待审核<span style="color: #3681f0"></span
          ></template>
        </a-tab-pane>
        <a-tab-pane key="1" tab="公开"></a-tab-pane>
        <a-tab-pane key="2" tab="隐藏"></a-tab-pane>
      </a-tabs>
      <a-table
        class="table-template"
        :columns="columns"
        :data-source="tableData"
        :rowKey="(item) => item.evaluationId"
        @change="onPage"
        :pagination="{
          hideOnSinglePage: true,
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="operation" slot-scope="text, record">
          <div class="btn_router_link">
            <router-link
              to
              @click.native="showModal(record)"
              v-show="record.state === 0"
              >审核</router-link
            >
            <router-link
              to=""
              @click.native="showModal(record)"
              v-show="record.state !== 0"
              >详情</router-link
            >
          </div>
        </template>
      </a-table>
    </div>
    <!-- 审核/详情弹框 -->
    <a-modal
      title=""
      :visible="visible"
      :confirm-loading="confirmLoading"
      v-if="evaluationObj"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button
          @click="auditItem(1)"
          v-show="evaluationObj.state === 0"
          key="submit"
          type="primary"
          :loading="loading"
        >
          公开
        </a-button>
        <a-button v-show="evaluationObj.state === 0" key="back" @click="hide()">
          隐藏
        </a-button>
        <a-button
          v-if="evaluationObj.state === 0"
          class="all_btn_border btnBorder"
          @click="handleCancel"
          >暂不审核</a-button
        >
        <a-button @click="handleCancel" v-else>关闭</a-button>
      </template>
      <div class="userNameAusor">
        <img :src="evaluationObj.userIcon" alt="" />
        <p>{{ evaluationObj.userName }}</p>
      </div>
      <div class="classMessage">
        <img :src="evaluationObj.productPhoto" alt="" />
        <div class="message">
          <p class="title">{{ evaluationObj.productName }}</p>
          <!-- <p class="time">第一期：2021/12/23-2021/12/30</p> -->
        </div>
      </div>
      <div class="messItem">
        评价：<img
          src="@/assets/image/flower.png"
          v-for="(item, index) in evaluationObj.evaluation"
          :key="index"
          alt=""
        />
      </div>
      <p class="messItem">点评：{{ evaluationObj.comment }}</p>
      <!-- <p class="messItem">审核状态：{{ evaluationObj.stateTxt }}</p> -->
      <p class="messItem" v-if="evaluationObj.reply">
        回复：{{ evaluationObj.reply }}
      </p>
      <div
        class="textArea"
        v-else-if="evaluationObj.reply || evaluationObj.state == 0"
      >
        <p>回复：</p>
        <a-textarea
          v-model="reply"
          placeholder=""
          :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "用户名",
    align: "center",
    dataIndex: "userName",
  },
  {
    title: "评价",
    align: "center",
    dataIndex: "evaluation",
  },
  {
    title: "评价状态",
    align: "center",
    dataIndex: "stateTxt",
  },
  {
    title: "是否回复",
    align: "center",
    dataIndex: "reply",
  },
  {
    title: "操作",
    align: "center",
    fixed: "right",
    width: "160px",
    scopedSlots: { customRender: "operation" },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      evaluationObj: null, // 当前操作对象
      evaluation: undefined, // 评价
      keyword: "", // 关键字
      tabIndex: "3", // 状态
      loading: false,
      reply: "", // 回复
      columns,
      tableData: [], // 列表数据
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      visible: false, // 弹框状态
      confirmLoading: false, // 按钮状态
    };
  },
  // 事件处理器
  methods: {
    // 重置
    reset(){
      this.keyword = '';
      this.evaluation = undefined;
      this.getManageList();
    },
    // 隐藏
    hide() {
      let _this = this;
      this.$confirm({
        title: "隐藏",
        content:
          "您隐藏该用户的评价后，用的评价将不被其他人看见，您的回复与用户的评价仅其本人可见。",
        onOk() {
          _this.auditItem(2);
        },
        onCancel() {},
      });
    },
    // tab切换
    callback(e) {
      this.tabIndex = e;
      this.pageNumber = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getManageList();
    },
      // 搜索
    search() {
      this.pageNumber = 1;
      // this.pageSize = 10;
      this.getManageList();
    },
    // 好评下拉
    handleChange(e) {
      // console.log(e);
    },
    showModal(e) {
      this.$ajax({
        url: "/hxclass-management/order-comment/" + e.evaluationId,
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.evaluationObj = res.data;
          this.visible = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleOk(e) {
      this.ModalText = "The modal will be closed after two seconds";
      this.confirmLoading = true;
      setTimeout(() => {
        this.visible = false;
        this.confirmLoading = false;
      }, 2000);
    },
    handleCancel(e) {
      this.reply = '';
      this.evaluationObj = null;
      this.visible = false;
    },
    // 审核评价
    auditItem(e) {
      this.$ajax({
        url: "/hxclass-management/order-comment/audit",
        method: "put",
        params: {
          evaluationId: this.evaluationObj.evaluationId,
          reply: this.reply,
          state: e, // 状态：1.公开 2.隐藏
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.handleCancel();
          this.getManageList()
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取评价列表
    getManageList() {
      this.$ajax({
        url: "/hxclass-management/order-comment/",
        method: "get",
        params: {
          keyword: this.keyword,
          evaluation: this.evaluation,
          state: this.tabIndex == 3 ? "" : this.tabIndex,
          pageNum: this.pageNumber,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.textArea {
  display: flex;
  margin-top: 20px;
  padding-left: 80px;
  padding-right: 80px;
  p {
    width: 50px;
  }
}
.messItem {
  padding-top: 20px;
  padding-left: 80px;
  padding-right: 80px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }
}
.classMessage {
  margin-top: 24px;
  display: flex;
  img {
    width: 150px;
    height: 100px;
  }
  .message {
    padding-left: 20px;
    .title {
      font-size: 20px;
      font-weight: bold;
    }
    .time {
      margin-top: 30px;
    }
  }
}
.userNameAusor {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
  p {
    margin-left: 10px;
  }
}
.findItem {
  padding-bottom: 24px;
  display: flex;
  align-items: center;
  /deep/.ant-select {
    width: 193px !important;
    height: 29px !important;
    margin-right: 0 !important;
    margin-left: 24px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin: 0 !important;
    margin-right: 24px !important;
  }
  /deep/.ant-input {
    width: 240px !important;
    height: 29px !important;
    font-size: 12px !important;
    color: #999999 !important;
    line-height: 14px !important;
    margin-bottom: 0 !important;
  }
  .ant-btn {
    margin-left: 24px;
  }
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
/deep/.ant-modal-footer {
  text-align: center;
  border: 0;
}
</style>
